.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .loader-box {
    display: flex;
    align-items: center;
    background: #f8f9fa;
    padding: 10px 20px;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .loader-icon {
    font-size: 24px; /* Adjust size as needed */
    animation: spin 1.5s linear infinite;
  }
  
  .loader-text {
    margin-left: 10px;
    font-weight: 600;
    color: #333;
    font-size: 16px;
  }
  
  /* Spinning animation */
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  