/* Popup.css */

/* Overlay styles */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Popup container styles */
  .popup-container {
    background: white;
    padding: 30px;
    border-radius: 8px;
    max-width: 400px;
    width: 100%;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  /* Success message styles */
  .popup-success {
    background-color: #d4edda;
    border-color: #c3e6cb;
  }
  
  /* Error message styles */
  .popup-error {
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }
  
  /* Icon styles */
  .popup-icon {
    font-size: 40px;
    width: 60px;  /* Fixed width */
    height: 60px; /* Fixed height */
    line-height: 60px; /* Centering the icon vertically */
    padding: 0; /* No padding required */
    border-radius: 50%; /* Circular shape */
    background-color: white;
    border: 3px solid;
    margin-bottom: 15px; /* Space between icon and message */
    display: flex;
    justify-content: center;
    align-items: center; /* Center icon horizontally */
  }
  
  /* Success icon styles */
  .popup-success-icon {
    color: #28a745;
    border-color: #28a745;
  }
  
  /* Error icon styles */
  .popup-error-icon {
    color: #dc3545;
    border-color: #dc3545;
  }
  
  /* Button styles */
  .popup-button {
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  /* Button hover effect */
  .popup-button:hover {
    background-color: #0056b3;
  }
  