// 
// _table.scss
// 

.table {
  td.is-receivable {
    background-color: #d5d3f9 !important; // is-receivable
    // color: #000 !important;

    /* Disable hover effect */
    &:hover {
      background-color: #d5d3f9 !important; // Keep the same background color
      // color: #000 !important;
    }
  }

  td.is-nouserid {
    background-color: #fbc7c6 !important; // is-nouserid
    // color: #000 !important;

    /* Disable hover effect */
    &:hover {
      background-color: #fbc7c6 !important; // Keep the same background color
      // color: #000 !important;
    }
  }

  /* General hover effect for all other <td> elements */
  td:hover {
    background-color: inherit !important;
    color: inherit !important;
  }

  th {
    font-weight: $font-weight-bold;
  }
}



.table-dark>:not(:last-child)>:last-child>* {
  border-bottom-color: $table-dark-border-color;
}

.table-bordered {
  border: $table-border-width solid $table-border-color;
}

.table-nowrap {

  th,
  td {
    white-space: nowrap;
  }
}

.table>:not(:first-child) {
  border-top: 0;
}